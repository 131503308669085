import { format } from "date-fns"
import { FC } from "react"
import { BiBlock, BiSolidCheckCircle } from "react-icons/bi"
import { PieProgressIndicator } from "../../../features/GanttChart/components/PieProgressIndicator"
import { DigestDataDetails } from "../../../graphql/generated/client-types-and-hooks"

export const DigestProjectProgress: FC<{ project: DigestDataDetails; chosenDate: Date }> = ({
  project,
  chosenDate,
}) => {
  const completedDateFormatted = project.isComplete ? format(project.isComplete, "yyyy-MM-dd") : null
  const chosenDateFormatted = format(chosenDate, "yyyy-MM-dd")

  return (
    <div className="flex items-center gap-x-2">
      <div className="flex items-center">
        {(!project.dailyProgress || project.dailyProgress === 0) && completedDateFormatted !== chosenDateFormatted && (
          <BiBlock className="size-7" color="gray" />
        )}
        {completedDateFormatted === chosenDateFormatted && <BiSolidCheckCircle className="size-8" color="green" />}
        {(!project.isComplete || completedDateFormatted !== chosenDateFormatted) &&
          project.dailyProgress != 0 &&
          project.dailyProgress && (
            <PieProgressIndicator progress={project?.dailyProgress ?? 0} color="green" sizeMedium={true} />
          )}
      </div>
      <div>
        <span>{`${project.dailyProgress ?? 0}% progress`}</span>
      </div>
    </div>
  )
}
