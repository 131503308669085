import { Button, ButtonGroup, LinearProgress, useMediaQuery, useTheme } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { clsx } from "clsx"
import { addDays, format, isToday } from "date-fns"
import { FC, useState } from "react"
import { BiCalendar, BiChevronLeft, BiChevronRight } from "react-icons/bi"
import { useQuery } from "urql"
import { DigestDataDetails } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { getMidnightForDayInTimeZone } from "../../../helpers/dateAndTime/getMidnightForDayInTimeZone"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import { CrewViewDatePickerMUI } from "../../CrewViewDatePickerMUI"
import { DashboardCard } from "../../Partials/Dashboard/DashboardCard"
import { EmptyStateBlock } from "../../Table/EmptyStateBlock"
import { DigestProductionRate } from "./DigestProductionRate"
import { DigestProjectBadgeWrapper } from "./DigestProjectBadgeWrapper"
import { DigestProjectProgress } from "./DigestProjectProgress"
import { DigestTasksCompleted } from "./DigestTasksCompleted"
import { DigestTodaysHours } from "./DigestTodaysHours"

const DailyDigestWidgetQuery = graphql(`
  query DailyDigestWidget($rangeStart: DateTime!, $rangeEnd: DateTime!) {
    myDivision {
      id
      imageUrl
      name
      dailyDigestData(rangeStart: $rangeStart, rangeEnd: $rangeEnd) {
        projectId
        name
        code
        imageUrl
        isComplete
        isArchived
        timeEntriesSum
        tasksCompletedSum
        dailyProgress
        project {
          productionRate(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
          deliveredPrimaryGoalUnits(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
          estimatedHours
          primaryGoalTargetQuantity
        }
      }
    }
  }
`)

type DataGridProject = DigestDataDetails

export const DailyDigestWidget: FC = () => {
  const [chosenDate, setChosenDate] = useState(new Date())

  const updateDate = (newDate: Date) => {
    setChosenDate(newDate)
  }

  const isNextDisabled = format(chosenDate, "yyyy-MM-dd") >= format(new Date(), "yyyy-MM-dd")
  const adjustDate = (days: number) => addDays(chosenDate, days)

  const { organization } = useCurrentUser()

  const [{ data }] = useQuery({
    query: DailyDigestWidgetQuery,
    variables: {
      rangeStart: getMidnightForDayInTimeZone(format(chosenDate, "yyyy-MM-dd"), organization.timezone),
      rangeEnd: getMidnightForDayInTimeZone(format(addDays(chosenDate, 1), "yyyy-MM-dd"), organization.timezone),
    },
  })

  const dailyDigestData = data?.myDivision?.dailyDigestData || []

  const projects = dailyDigestData || []

  const theme = useTheme()
  const tabletScreen = useMediaQuery(theme.breakpoints.down("xl"))
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Active Projects",
      minWidth: tabletScreen ? 250 : 300,
      renderCell: ({ row: project }: GridRenderCellParams<DataGridProject>) =>
        data?.myDivision && <DigestProjectBadgeWrapper project={project} queryData={data} chosenDate={chosenDate} />,
    },
    {
      field: "projectProgress",
      headerName: "Project Progress",
      renderCell: ({ row: project }: GridRenderCellParams<DataGridProject>) => (
        <DigestProjectProgress project={project} chosenDate={chosenDate} />
      ),
    },
    {
      field: "todaysHours",
      headerName: "Today's Hours",
      renderCell: ({ row: project }: GridRenderCellParams<DataGridProject>) => <DigestTodaysHours project={project} />,
    },
    {
      field: "productionRate",
      headerName: "Production Rate",
      renderCell: ({ row: projectRow }: GridRenderCellParams<DataGridProject>) => (
        <DigestProductionRate project={projectRow.project} />
      ),
    },
    {
      field: "tasksCompletedSum",
      headerName: "Tasks Completed",
      renderCell: ({ row: project }: GridRenderCellParams<DataGridProject>) => (
        <DigestTasksCompleted project={project} />
      ),
    },
  ].map((col) => ({
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    minWidth: 150,
    ...col,
  }))

  return (
    <DashboardCard className="pb-1 min-h-72">
      <div className={clsx("flex justify-between", mobileScreen && "gap-y-6 flex-col")}>
        <h2 className="text-2xl font-semibold">Daily Digest</h2>
        <div className={clsx("flex flex-wrap gap-2", mobileScreen && "flex-col")}>
          <CrewViewDatePickerMUI
            onChange={(newDate) => newDate && updateDate(newDate)}
            format="MMMM dd"
            value={chosenDate}
            slots={{ openPickerIcon: BiCalendar }}
            disableFuture
          />
          <div className="flex gap-x-2">
            <div>
              <ButtonGroup>
                <Button
                  className="min-w-0 p-2"
                  color="secondary"
                  variant="contained"
                  onClick={() => updateDate(adjustDate(-1))}
                >
                  <BiChevronLeft className="size-6" />
                </Button>
                <Button
                  className="min-w-0 p-2"
                  color="secondary"
                  variant="contained"
                  onClick={() => !isNextDisabled && updateDate(adjustDate(1))}
                  disabled={isNextDisabled}
                >
                  <BiChevronRight className="size-6" />
                </Button>
              </ButtonGroup>
            </div>
            <div>
              <Button
                type="button"
                color="secondary"
                variant="contained"
                onClick={() => updateDate(new Date())}
                disabled={isToday(chosenDate)}
              >
                Today
              </Button>
            </div>
          </div>
        </div>
      </div>
      <DataGridPro
        columns={columns}
        rows={projects}
        getRowId={(row) => row.projectId}
        pagination
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: () => <EmptyStateBlock label="No projects with progress on this date" />,
        }}
        disableRowSelectionOnClick
      />
    </DashboardCard>
  )
}
