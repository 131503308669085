import { clsx } from "clsx"
import { FC, ReactNode } from "react"
import { overrideTailwindClasses } from "tailwind-override"

type Props = {
  children: ReactNode
  className?: string
}

export const DashboardCard: FC<Props> = ({ children, className }) => {
  return (
    <div
      className={overrideTailwindClasses(
        clsx("shadow-lg lg:shadow-none border rounded-lg pt-4 pb-8 px-4 lg:px-10 h-full", className)
      )}
    >
      {children}
    </div>
  )
}
