import { Button, Typography } from "@mui/material"
import Link from "next/link"
import { FC, useContext } from "react"
import { BiEditAlt, BiNavigation, BiSolidCircle, BiSortAlt2 } from "react-icons/bi"
import { useQuery } from "urql"
import { getDirectionsMapUrl } from "../../../features/Maps/utils/helpers"
import { graphql } from "../../../graphql/generated/gql"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { successSnack } from "../../Notistack/ThemedSnackbars"
import { DashboardCard } from "../../Partials/Dashboard/DashboardCard"
import { DashboardCardHeader } from "../../Partials/Dashboard/DashboardCardHeader"
import { DrawerLink } from "../../Partials/Drawer/components/Elements/DrawerLink"
import { TaskDrawer } from "../../Partials/Drawer/components/Task/TaskDrawer"
import { DrawerContext } from "../../Partials/Drawer/providers/DrawerProvider"
import { ReassignUserTaskForm } from "../../Partials/User/ReassignUserTaskForm/ReassignUserTaskForm"
import { ProjectImage } from "../../ProjectImage"
import { ProjectUnitReportForm } from "../../ProjectUnitReportForm"
import { RenderIf } from "../../RenderIf"
import DeprecatedModal from "../../deprecated/StandardModal"

const GetCurrentAssignment = graphql(`
  query CurrentAssignment {
    currentUser {
      id
      isClockedIn
      currentProject {
        id
        isArchived
        isComplete
        imageUrl
        location {
          lat
          lng
          radius
        }
        name
        code
      }
      currentProjectId
      currentTask {
        id
        name
        projectId
      }
      currentTaskId
      firstName
      imageUrl
      jobTitle
      lastName
      projectId
      taskId
      organization {
        id
        imageUrl
        name
      }
    }
  }
`)

export const CurrentAssignmentWidget: FC = () => {
  const reassignUserTaskFormModalProps = useModalProps("Reassign Users")
  const { push: pushDrawer } = useContext(DrawerContext)

  const [{ data }, refetch] = useQuery({
    query: GetCurrentAssignment,
  })

  if (!data) {
    return null
  }

  const { currentProjectId, currentProject, currentTask, organization } = data.currentUser

  return (
    <DashboardCard>
      <DashboardCardHeader title="Current Assignment" />
      <div className="flex flex-col items-center md:items-start gap-y-4 md:gap-y-6">
        <div className="flex justify-between w-full gap-x-2">
          <div className="flex flex-col gap-x-4 gap-y-2 w-full overflow-hidden text-balance">
            <div className="flex basis-full md:basis-1/2 items-start whitespace-nowrap gap-x-2 mb-4">
              <Typography variant="h6" className="my-0 text-base font-bold hover:no-underline">
                Project
              </Typography>
              <Link key={currentProjectId} href={`/projects/${currentProjectId}`} className="w-full md:w-fit">
                <Typography
                  variant="h6"
                  className="cursor-pointer hover:underline underline-offset-1 my-0 text-base text-gray-500 text-balance"
                >
                  {`${currentProject.name} / ${currentProject.code}`}
                </Typography>
              </Link>
            </div>
            <div className="flex basis-full md:basis-1/2 items-center whitespace-nowrap gap-x-2">
              <BiSolidCircle className="size-2" color={data.currentUser.isClockedIn ? "blue" : "gray"} />
              <Typography variant="h6" className="my-0 text-base font-bold hover:no-underline">
                Task
              </Typography>
              <DrawerLink component={<TaskDrawer taskId={currentTask.id} />} drawerName="Task">
                <Typography
                  variant="h6"
                  className="text-gray-500 hover:underline underline-offset-1 my-0 text-base text-balance text-left"
                >
                  {currentTask.name}
                </Typography>
              </DrawerLink>
            </div>
          </div>
          <div className="justify-center rounded size-12 bg-gray-100 sm:block hidden">
            <ProjectImage organization={organization} project={currentProject} />
          </div>
        </div>
        <div className="flex flex-col w-full md:flex-row gap-y-4 gap-x-2">
          <RenderIf permissionsInclude="task:update">
            <div className="flex flex-col md:flex-row gap-y-4 gap-x-2">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<BiEditAlt size={18} />}
                disableElevation
                fullWidth
                onClick={() => pushDrawer(<ProjectUnitReportForm projectId={currentProjectId} />, "ProjectUnitReport")}
              >
                Report
              </Button>
            </div>
          </RenderIf>

          {currentProject.location && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<BiNavigation />}
              href={getDirectionsMapUrl(currentProject.location)}
              target="_blank"
            >
              Directions
            </Button>
          )}

          <RenderIf permissionsInclude="user:assign" context={{ userId: data.currentUser.id }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<BiSortAlt2 className="rotate-90" />}
              onClick={reassignUserTaskFormModalProps.handleOpenModal}
            >
              Reassign
            </Button>
            {reassignUserTaskFormModalProps.isOpen && (
              <DeprecatedModal className="overflow-y-visible" {...reassignUserTaskFormModalProps}>
                <ReassignUserTaskForm
                  onCancel={() => {
                    reassignUserTaskFormModalProps.handleCloseModal()
                  }}
                  onSuccess={() => {
                    successSnack("Selected users have been successfully reassigned.")
                    reassignUserTaskFormModalProps.handleCloseModal()
                    refetch()
                  }}
                  preselectedUsers={[data.currentUser]}
                  projectId={currentProjectId}
                />
              </DeprecatedModal>
            )}
          </RenderIf>
        </div>
      </div>
    </DashboardCard>
  )
}
export default CurrentAssignmentWidget
