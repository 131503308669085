import { zonedTimeToUtc } from "date-fns-tz"

export const isValidTimeZone = (timeZone: string) => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: timeZone })
    return true
  } catch (_) {
    return false
  }
}

/*
 * example:
 * getMidnightForDayInTimeZone("2022-01-01", "America/Denver")
 */
export const getMidnightForDayInTimeZone = (date: string | number | Date, IANATimeZone: string): Date => {
  if (!isValidTimeZone(IANATimeZone)) throw new Error(`"${IANATimeZone}" is not a valid input. Expected IANA time zone`)

  return zonedTimeToUtc(date, IANATimeZone)
}
