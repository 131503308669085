import { Button } from "@mui/material"
import { FC, useContext } from "react"
import { useMarkAllNotificationsReadMutation } from "../../../graphql/generated/client-types-and-hooks"
import { DrawerContext } from "../../Partials/Drawer/providers/DrawerProvider"
import { NotificationsContext } from "../../../providers/NotificationsProvider"
import { H4 } from "../../deprecated"
import { DashboardCard } from "../../Partials/Dashboard/DashboardCard"
import { NotificationsDrawer, UserNotificationRow } from "../../Partials/Drawer/components/NotificationsDrawer"

export const NotificationsWidget: FC = () => {
  const { push: pushDrawer } = useContext(DrawerContext)
  const { notifications } = useContext(NotificationsContext)

  const [_, markAllRead] = useMarkAllNotificationsReadMutation()

  const last3UnreadNotifications = notifications?.filter((notification) => !notification.markedReadAt).slice(0, 3)
  const hasNotifications = last3UnreadNotifications?.length !== 0
  const hasUnreadNotifications = last3UnreadNotifications?.some((notification) => !notification.markedReadAt)

  return (
    <DashboardCard className="pb-3">
      <div className="flex items-baseline justify-between gap-x-2">
        <H4 className="mb-2 mt-0">Notifications</H4>
        <Button
          className="focus:outline-none"
          disabled={!hasUnreadNotifications}
          onClick={() => markAllRead({})}
          variant="text"
        >
          Mark all as read
        </Button>
      </div>
      {!hasNotifications && <div className="text-center text-gray-500 py-4">No notifications</div>}

      {hasNotifications && (
        <div className="border-t mt-2">
          {last3UnreadNotifications.map((notification) => (
            <UserNotificationRow key={notification.id} notification={notification} />
          ))}
        </div>
      )}

      {hasNotifications && (
        <div className="flex justify-center pt-3">
          <Button
            className="focus:outline-none"
            onClick={() => {
              pushDrawer(<NotificationsDrawer />, "Notifications")
            }}
            variant="text"
          >
            View all
          </Button>
        </div>
      )}
    </DashboardCard>
  )
}
