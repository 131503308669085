import { clsx } from "clsx"
import { format } from "date-fns"
import { FC } from "react"
import { BiHash, BiSolidCheckCircle } from "react-icons/bi"
import { Division, Organization, Project } from "../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../types/helpers"
import { ProjectImage } from "../../ProjectImage"

type Props = {
  division?: PickPlus<Organization | Division, "id" | "imageUrl" | "name">
  project: PickPlus<Project, "id" | "code" | "isArchived" | "isComplete" | "imageUrl" | "name">
  className?: string
  disableHover?: boolean
  chosenDate: Date
}

export const DigestProjectBadge: FC<Props> = ({ division, project, className, disableHover = false, chosenDate }) => {
  const completedDateFormatted = project.isComplete ? format(project.isComplete, "yyyy-MM-dd") : null
  const chosenDateFormatted = format(chosenDate, "yyyy-MM-dd")
  return (
    <div className={clsx("flex gap-4 items-center", className)}>
      {completedDateFormatted && completedDateFormatted <= chosenDateFormatted && (
        <BiSolidCheckCircle className="size-10" color="green" />
      )}
      {(!project.isComplete || (completedDateFormatted && completedDateFormatted > chosenDateFormatted)) && (
        <ProjectImage organization={division} width="w-12 md:w-10" height="h-12 md:h-10" project={project} hideLabels />
      )}
      <div className="grid justify-center content-center gap-y-0.5">
        <div
          className={clsx(
            "mt-1 truncate leading-tight font-medium md:font-normal text-base",
            disableHover ? "" : "cursor-pointer hover:underline underline-offset-1 ",
            completedDateFormatted && completedDateFormatted <= chosenDateFormatted ? "line-through" : ""
          )}
        >
          {project.name}
        </div>
        {project.code && (
          <div className="flex items-center text-gray-600 text-xs">
            <BiHash />
            <div className="truncate">{project.code}</div>
          </div>
        )}
      </div>
    </div>
  )
}
