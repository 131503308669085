import { FC } from "react"
import { BiSolidStopwatch } from "react-icons/bi"
import { DigestDataDetails } from "../../../graphql/generated/client-types-and-hooks"
import { HoursAndMinutes } from "../../Time/HoursAndMinutes"

export const DigestTodaysHours: FC<{ project: DigestDataDetails }> = ({ project }) => {
  return (
    <div className="flex items-center gap-x-2">
      <BiSolidStopwatch className="size-6" color={project.timeEntriesSum ? "blue" : "gray"} />
      <HoursAndMinutes seconds={project.timeEntriesSum || 0} />
    </div>
  )
}
