import { FC } from "react"
import { H2 } from "../components/deprecated"
import Layout from "../components/Layout/Layout"
import { HomeWidgets } from "../components/Widgets/HomeWidgets"
import { useCurrentUser } from "../providers/PermissionsProvider/currentUserProvider"

const Home: FC = () => {
  const { firstName } = useCurrentUser()
  return (
    <Layout title="Home">
      <div className="flex flex-col gap-4">
        <H2 data-testid="greeting">Hi, {firstName}</H2>
        <HomeWidgets />
      </div>
    </Layout>
  )
}

export default Home
