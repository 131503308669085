import { FC } from "react"
import { DailyDigestWidgetQuery, DigestDataDetails } from "../../../graphql/generated/client-types-and-hooks"
import { PermissionedLink } from "../../Partials/Projects/PermissionedLink"
import { DigestProjectBadge } from "./DigestProjectBadge"

type Props = {
  project: DigestDataDetails
  queryData: DailyDigestWidgetQuery
  chosenDate: Date
}

export const DigestProjectBadgeWrapper: FC<Props> = ({ project, queryData, chosenDate }) => {
  const { projectId: id, ...projectInfo } = project
  const adjustedProject = { id, ...projectInfo }
  return (
    <PermissionedLink
      permissions={["project:read"]}
      projectId={project.projectId}
      href={`/projects/${project.projectId}/summary/`}
    >
      <DigestProjectBadge division={queryData?.myDivision} project={adjustedProject} chosenDate={chosenDate} />
    </PermissionedLink>
  )
}
