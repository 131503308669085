import { FC, ReactNode, useContext } from "react"
import { PermissionsContext } from "../../providers/PermissionsProvider/PermissionsProvider"
import { GrantedPermission } from "../../types/Permission"

type PermissionedWidgetProps = {
  children: ReactNode
  className?: string
  permission: GrantedPermission | GrantedPermission[]
}

export const PermissionedWidget: FC<PermissionedWidgetProps> = ({ children, className, permission }) => {
  const { hasGrantedPermission } = useContext(PermissionsContext)

  const hasPermission = Array.isArray(permission)
    ? permission.some((p) => hasGrantedPermission(p))
    : hasGrantedPermission(permission)

  return hasPermission ? <div className={className}>{children}</div> : null
}
