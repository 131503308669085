import { Box, Button, Grid, Typography } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useQuery } from "urql"
import { graphql } from "../../graphql/generated/gql"
import { colors } from "../../helpers/styles/colors"
import { useAssetListGroupConsolidation } from "../../hooks/useAssetListGroupConsolidation"
import { useCurrentUser } from "../../providers/PermissionsProvider/currentUserProvider"
import { AssetBadge } from "../AssetBadge"
import { DashboardCardHeader } from "../Partials/Dashboard/DashboardCardHeader"
import { DashboardCard } from "../Partials/Dashboard/DashboardCard"
import { SkeletonContainer } from "../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../Skeletons/SkeletonElement"

const MyAssetsQuery = graphql(`
  query UserAssetsList($userId: String!) {
    assets(deleted: false, userId: $userId, includeGroupedAssets: false) {
      id
      active
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      companyAssetNumber
      compositeKey
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
    assetGroups(userId: $userId) {
      assetGroupId
      assignableId
      assignableType
      compositeKey
      count
      status
      groupParent {
        id
        name
        imageUrl
        ownershipType
      }
    }
  }
`)

export const MyAssetsWidget: FC = () => {
  const { id: userId } = useCurrentUser()
  const [{ data, fetching }, getAssets] = useQuery({
    query: MyAssetsQuery,
    variables: { userId },
    pause: true,
  })
  const [showAll, setShowAll] = useState(false)
  const assets = useAssetListGroupConsolidation(data)
  const assetsCount = assets?.length ?? 0
  useEffect(() => {
    if (userId && !data) {
      getAssets()
    }
  }, [assets, data, getAssets, userId])

  return (
    <>
      <DashboardCard className="pb-3">
        <DashboardCardHeader title="My Assets" subtitle={String(assetsCount)} />
        {fetching && !data ? (
          <SkeletonContainer>
            <SkeletonElement className="h-6" />
          </SkeletonContainer>
        ) : assetsCount > 0 ? (
          <div>
            <Grid container spacing={1.5}>
              {(showAll ? assets : assets.slice(0, 6))?.map((asset) => (
                <Grid key={asset.id} item lg={6} md={6} xs={12}>
                  <Box borderRadius="8px" alignItems="center" padding="10px" bgcolor={colors.gray[100]} width="auto">
                    <AssetBadge asset={asset} />
                  </Box>
                </Grid>
              ))}
            </Grid>
            {assetsCount > 6 && (
              <div className="flex justify-center mt-2">
                <Button onClick={() => (showAll ? setShowAll(false) : setShowAll(true))}>
                  {showAll ? "Show Less" : "View All"}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <Typography variant="body1" className="text-center text-gray-500 mb-4 py-4">
            No assets assigned
          </Typography>
        )}
      </DashboardCard>
    </>
  )
}
