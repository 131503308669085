import { FC } from "react"
import { CurrentAssignmentWidget } from "./BlockWidgets/CurrentAssignmentWidget"
import { NotificationsWidget } from "./BlockWidgets/NotificationsWidget"
import { TimeClockWidget } from "./BlockWidgets/TimeClockWidget"
import { DailyDigestWidget } from "./DailyDigest/DailyDigestWidget"
import { MyAssetsWidget } from "./MyAssetsWidget"
import { PermissionedWidget } from "./PermissionedWidget"
import { useCurrentUser } from "../../providers/PermissionsProvider/currentUserProvider"
import { DefaultDivisionWidget } from "./BlockWidgets/DefaultDivisionWidget"

export const HomeWidgets: FC = () => {
  const { isCurrentDivision } = useCurrentUser()
  return (
    <>
      <div className="flex flex-col gap-y-4">
        {isCurrentDivision ? (
          <>
            <div className="flex flex-col gap-y-4 lg:flex-row lg:gap-x-4 min-h-72">
              <div className="flex-1">
                {" "}
                <TimeClockWidget />
              </div>
              <PermissionedWidget className="flex-1" permission={["project:read:*", "project:read:assigned-project"]}>
                <CurrentAssignmentWidget />
              </PermissionedWidget>
            </div>

            <div className="flex flex-col gap-y-4 lg:flex-row lg:gap-x-4 min-h-72">
              <div className="flex-1">
                <PermissionedWidget permission={["asset:read:assigned-self"]} className="h-full">
                  <MyAssetsWidget />
                </PermissionedWidget>
              </div>
              <div className="flex-1">
                <NotificationsWidget />
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-y-4 lg:flex-row lg:gap-x-4 min-h-72">
            <div className="flex-1">
              <DefaultDivisionWidget />
            </div>
            <div className="flex-1">
              <NotificationsWidget />
            </div>
          </div>
        )}
      </div>
      <PermissionedWidget permission="project:read:*">
        <DailyDigestWidget />
      </PermissionedWidget>
    </>
  )
}
