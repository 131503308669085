import { FC } from "react"
import { BiSolidCheckCircle } from "react-icons/bi"
import { DigestDataDetails } from "../../../graphql/generated/client-types-and-hooks"

export const DigestTasksCompleted: FC<{ project: DigestDataDetails }> = ({ project }) => {
  return (
    <div className="flex items-center gap-x-2">
      <BiSolidCheckCircle className="size-6" color={project.tasksCompletedSum > 0 ? "green" : "gray"} />
      <span>{`${project.tasksCompletedSum} completed`}</span>
    </div>
  )
}
