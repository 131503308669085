import { FC } from "react"
import { BiBlock, BiChevronsDown, BiChevronsUp } from "react-icons/bi"
import { ProjectProductionRateWidgetQuery } from "../../../graphql/generated/client-types-and-hooks"

type DigestProductionRateProps = {
  project: ProjectProductionRateWidgetQuery["project"]
}

export const DigestProductionRate: FC<DigestProductionRateProps> = ({ project }) => {
  const ratePercentage = Math.round(project!.productionRate)
  return (
    <div className="flex items-center gap-x-2">
      {ratePercentage === 0 && <BiBlock className="size-6" color="gray" />}
      {ratePercentage >= 100 && <BiChevronsUp className="size-6" color="green" />}
      {ratePercentage < 100 && ratePercentage > 0 && <BiChevronsDown className="size-6 text-orange-600" />}
      <span>{ratePercentage > 0 ? `${ratePercentage}%` : "N/A"}</span>
    </div>
  )
}
