import { FC, ReactNode } from "react"
import { RiArrowRightCircleFill } from "react-icons/ri"
import { H4 } from "../../deprecated"
import { Typography } from "@mui/material"

type Props = {
  title: string | ReactNode
  withIcon?: boolean
  subtitle?: string
}

export const DashboardCardHeader: FC<Props> = ({ title, withIcon, subtitle }) => {
  return (
    <>
      {subtitle ? (
        <div className="flex items-center">
          <Typography variant="h4" className="text-gray-800 text-2xl">
            {title}
          </Typography>
          <Typography variant="h4" className="text-gray-500 text-2xl ml-2">
            {subtitle}
          </Typography>
        </div>
      ) : (
        <div className="flex items-center justify-between mb-4">
          <H4 className="mb-2">{title}</H4>
          {withIcon && <RiArrowRightCircleFill className="size-7 text-blue-600" />}
        </div>
      )}
    </>
  )
}
